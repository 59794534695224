<template>
  <div>
    <div>
      <!-- button -->
      <b-button
        id="toggle-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="mb-1"
        variant="primary"
        @click="voltarParaUltimaRota"
      >
        <feather-icon
          icon="ArrowLeftCircleIcon"
          class="mr-50"
        />
        <span class="align-middle">Voltar</span>
      </b-button>
      <!-- modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        size="lg"
        title="Cadastrar Nova Seguradora"
        ok-title="Cadastrar"
        cancel-title="Cancelar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form>
            <b-row>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Nome Fantasia"
                  label-for="name-input"
                >
                  <b-form-input
                    id="name-input"
                    v-model="name"
                    placeholder="Nome"
                    :state="nameState"
                  />
                  <b-form-invalid-feedback :state="nameState">
                    Nome é obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label for="rasao-social">Razão Social</label>
                  <b-form-input
                    id="rasao-social"
                    v-model="razaoSocial"
                    type="text"
                    :state="razaoSocialState"
                    placeholder="Razão Social"
                  />
                  <b-form-invalid-feedback :state="razaoSocialState">
                    {{ razaoSocialState ? '' : 'Razão Social é obrigatório' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="CNPJ"
                  label-for="cnpj"
                  :state="cnpjState"
                >
                  <cleave
                    id="customDelimiter"
                    v-model="cnpj"
                    class="form-control"
                    :raw="false"
                    :options="{
                      numericOnly: true, ...mask.cnpj
                    }"
                    :state="cnpjState"
                    placeholder="00.000.000/0000-00"
                    :class="{'is-invalid': !cnpjState}"
                  />
                  <b-form-invalid-feedback :state="cnpjState">
                    {{ cnpjState ? '' : 'CNPJ é obrigatório' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Inscrição Estadual"
                  label-for="inscricao_estadual"
                >
                  <b-form-input
                    id="inscricao_estadual"
                    v-model="inscricaoEstadual"
                    placeholder="Inscrição Estadual"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                sm="12"
              >
                <b-form-group
                  label="CEP"
                  label-for="cep"
                >
                  <b-input-group>
                    <cleave
                      v-model="cep"
                      name="cep"
                      class="form-control"
                      :raw="false"
                      :options="{numericOnly: true, ...mask.cep}"
                      placeholder="00000-000"
                      @keyup.enter="consultaCEP()"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="consultaCEP()"
                      >
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <b-form-group>
                  <label>UF</label>
                  <b-form-input
                    id="cidade"
                    v-model="uf"
                    type="text"
                    placeholder="UF"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="5"
                sm="12"
              >
                <b-form-group>
                  <label>Cidade</label>
                  <b-form-input
                    id="cidade"
                    v-model="cidade"
                    type="text"
                    placeholder="cidade"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="8"
                sm="12"
              >
                <b-form-group>
                  <label for="rua">Rua</label>
                  <b-form-input
                    id="rua"
                    v-model="rua"
                    type="text"
                    placeholder="rua"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <b-form-group>
                  <label for="numero">Número</label>
                  <b-form-input
                    id="numero"
                    v-model="numero"
                    type="number"
                    placeholder="número"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label for="bairro">Bairro</label>
                  <b-form-input
                    id="bairro"
                    v-model="bairro"
                    type="text"
                    placeholder="bairro"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
                cols="2"
              >
                <b-form-group
                  label="Telefone"
                  label-for="telefone"
                >
                  <cleave
                    id="telefone"
                    v-model="telefone"
                    class="form-control"
                    :raw="false"
                    :options="{
                      numericOnly: true, ...mask.telefone
                    }"
                    placeholder="(99) 9999-9999"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
                cols="2"
              >
                <b-form-group
                  label="Celular"
                  label-for="Celular"
                >
                  <cleave
                    id="telefone"
                    v-model="celular"
                    class="form-control"
                    :raw="false"
                    :options="{
                      numericOnly: true, ...mask.telefone
                    }"
                    placeholder="(99) 9999-9999"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label for="email">Email</label>
                  <b-form-input
                    id="email"
                    v-model="email"
                    type="email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </form>
      </b-modal>
      <b-card>
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <b-row>
            <h4 class="ml-2">
              Seguradoras
            </h4>
            <b-col cols="12">
              <b-row align-h="between">
                <b-col
                  md="2"
                  sm="4"
                  class="my-1"
                >
                  <b-form-group class="mb-0">
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="8"
                  class="my-1"
                >
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    size="sm"
                    placeholder="Pesquisar"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(nome_fantasia)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :text="getInitials(data.item.nome_fantasia)"
                        :style="{ backgroundColor: randomColor(data.item.id) }"
                        :to="{
                          name: 'empresa-view',
                          params: { id: data.item.id },
                        }"
                      />
                    </template>
                    <b-link
                      :to="{
                        name: 'empresa-view',
                        params: { id: data.item.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ data.item.nome_fantasia }}
                    </b-link>
                  </b-media>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`delete-${data.item.id}`"
                      class="trash-icon"
                      icon="TrashIcon"
                      size="16"
                      @click="deletEmpresa(data.item.id, data.item.nome)"
                    />
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BForm,
  BAvatar,
  BMedia,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay,
  BModal,
  VBModal,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import axios from '../../../axios-auth'

export default {
  components: {
    BTable,
    BForm,
    BAvatar,
    BMedia,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BModal,
    Cleave,
    BOverlay,
    BFormInvalidFeedback,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      load: true,
      ativo: null,
      nome: '',
      razaoSocial: '',
      inscricaoEstadual: '',
      name: '',
      cnpj: '',
      cep: '',
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      telefone: '',
      email: '',
      uf: '',
      celular: '',
      selected: { title: '' },
      metodoPagamento: null,
      option: [{ title: 'Ativo' }, { title: 'Inativo' }],
      options: [{ title: 'Pix' }, { title: 'Boleto' }, { title: 'Cartão de Credito' }],
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 4, 4],
          uppercase: true,
        },
      },
      fields: [
        { key: 'id', label: 'id' },
        {
          key: 'nome',
          label: 'Seguradora',
          sortable: true,
        },
        { key: 'actions', label: 'ações' },
      ],
      colorCache: {},
      items: [],
      status: [
        {
          1: 'Ativo',
          0: 'Inativo',
        },
        {
          1: 'light-success',
          0: 'light-danger',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    nameState() {
      if (this.name) {
        return true
      }
      return false
    },
    razaoSocialState() {
      if (this.razaoSocial) {
        return true
      }
      return false
    },
    cnpjState() {
      if (this.cnpj) {
        return true
      }
      return false
    },
  },
  async created() {
    await this.updateList()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    voltarParaUltimaRota() {
      this.$router.go(-1)
    },
    closeModal() {
      this.id = null
      this.template = {}
      this.edit = false
      this.novoTemplate = false
    },
    async consultaCEP() {
      if (this.cep) {
        if (this.cep.replace(/[^0-9]/g, '').length === 8) {
          await axios
            .get(`https://viacep.com.br/ws/${this.cep}/json/`)
            .then(async res => {
              const { data } = res
              if (data.erro) {
                this.$swal({
                  title: 'ERROR',
                  text: 'CEP não encontrado',
                  icon: 'error',
                  customClass: {
                    comfirmButton: 'btn btn-danger',
                  },
                })
              } else {
                this.rua = data.logradouro
                this.bairro = data.bairro
                this.cidade = data.localidade
                this.uf = data.uf
              }
            })
            .catch(() => {
              this.$swal({
                title: 'ERROR',
                text: 'CEP não encontrado',
                icon: 'error',
                customClass: {
                  comfirmButton: 'btn btn-danger',
                },
              })
            })
        }
      }
      return null
    },
    deletEmpresa(id, nome) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o credor ${nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`api/empresas/delete/${id}`)
            .then(res => {
              if (res.data) {
                this.updateList()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Empresa deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              // Error
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar empresa!',
                  text: error.response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
    async updateList() {
      this.load = true
      await axios
        .get('api/seguradora/')
        .then(res => {
          const empresas = []
          res.data.dados.map(dt => {
            empresas.push(dt)
          })
          this.totalRows = empresas.length
          this.items = empresas
          this.load = false
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    resetModal() {
      this.name = null
      this.nameState = null
      this.cnpj = null
      this.inscricaoEstadual = null
      this.razaoSocial = null
      this.selected = null
      this.email = null
      this.cep = null
      this.rua = null
      this.bairro = null
      this.telefone = null
      this.cidade = null
      this.uf = null
      this.celular = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      const formData = {
        nome_fantasia: this.name,
        razao_social: this.razaoSocial,
        cnpj: this.cnpj.replace(/[^0-9]/g, ''),
        inscricao_estadual: this.inscricaoEstadual ? this.inscricaoEstadual.replace(/[^0-9]/g, '') : null,
        cep: this.cep,
        endereco: this.rua,
        bairro: this.bairro,
        numero: this.numero,
        telefone: this.telefone ? this.telefone.replace(/[^0-9]/g, '') : null,
        celular: this.celular ? this.celular.replace(/[^0-9]/g, '') : null,
        email: this.email,
        uf: this.uf,
        cidade: this.cidade,
      }
      await axios
        .post('api/empresas/store', formData)
        .then(res => {
          if (res.data) {
            this.updateList()
            this.$swal({
              title: 'Empresa cadastrado com sucesso',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs['my-modal'].toggle('#toggle-btn')
          }
        })
        .catch(error => {
          // Error
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.is-invalid {
  border-color: red !important;
}
</style>
